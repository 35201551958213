import { css } from "@emotion/react";
import { Link } from "gatsby";
import React, { ElementType } from "react";

import CongratulationBanner from "../assets/images/Confetti_Smaller.png";
import { ButtonBlueFilledRounded } from "../components/ui/Button";

export type CongratulationsProps = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  readonly Icon: ElementType<{}>;
};

const buttonCSS = css`
  width: 250px;
`;

export function Congratulations({ Icon }: CongratulationsProps) {
  return (
    <div>
      <img src={CongratulationBanner} alt="confetti" />
      <div
        css={css`
          margin-top: -25px;
        `}
        className="flex justify-center mb-10"
      >
        <Icon />
      </div>
      <b className="mt-5">
        Congratulations, you will receive your payback shortly
      </b>
      <p className="mt-4 mb-10">
        {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sit facilisi
      diam pretium, suspendisse vitae maecenas at. Convallis amet suspendisse
      hendrerit eu massa semper leo, venenatis. */}
      </p>
      <div className="flex justify-center">
        <Link to="/profile">
          <ButtonBlueFilledRounded css={buttonCSS}>OK</ButtonBlueFilledRounded>
        </Link>
      </div>
    </div>
  );
}
