export const colors = {
  "snap-black": "#222222",
  "snap-alabaster": "#F9F9F9",
  "snap-blue": "#2F80FF",
  "snap-sonic-silver": "#737373",
  "snap-ebony-clay": "#252B42",
  "snap-gainsboro": "#eae7e7",
  "snap-blue-matte": "#4772A0",
  "snap-picton-blue": "#23a6f0",
  "snap-mercury": "#E6E6E6",
  "snap-haiti": "#0C092A",
};
