import { RouteComponentProps, Router } from "@reach/router";
import React from "react";

import {
  RewardsMethodSection,
  RewardsRootSection,
  RewardsSection,
} from "../rewards";
import { SessionLoader } from "../session";
import { assertDefined } from "../utils";

export default function Rewards({ location }: RouteComponentProps) {
  assertDefined(location);

  return (
    <SessionLoader audience="authenticated" location={location}>
      <Router>
        <RewardsSection path="rewards">
          <RewardsRootSection path="/" />
          <RewardsMethodSection path="/:methodName" />
        </RewardsSection>
      </Router>
    </SessionLoader>
  );
}
