import { css } from "@emotion/react";
import { useLocation } from "@reach/router";
import React, { HTMLProps } from "react";

import SIcon from "../../../assets/images/SIconFooter.png";
import { sessionSignOut } from "../../../session";
import { useAppDispatch } from "../../../store";
import { CustomLink } from "../../NavBar";

type FooterPropsType = HTMLProps<HTMLDivElement>;

const Footer: React.FC<FooterPropsType> = ({ className }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  return (
    <div
      className={`grid grid-cols-3 bg-ral-9005 text-white min-h-300 h-max ${className}`}
      css={css`
        padding-top: 28px;
        padding-bottom: 34px;
        padding-left: 58px;
      `}
    >
      <div>
        <div
          className="grid grid-cols-max-content-auto items-center"
          css={css`
            margin-bottom: 20px;
            gap: 16px;
          `}
        >
          <img src={SIcon} alt="" />
          <p
            className="font-montserrat font-700 italic"
            css={css`
              font-size: 48px;
              line-height: 59px;
            `}
          >
            Swap
          </p>
        </div>
        <p
          className="text-white-70 italic font-200"
          css={css`
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 40px;
          `}
        >
          Healthy Choices Rewarded
        </p>
        <p
          className="text-white-70 font-200"
          css={css`
            font-size: 17px;
            line-height: 21px;
          `}
        >
          Copyright Swap 2024 - all rights reserved
        </p>
      </div>
      <div>
        <h3
          className="text-white-90 opacity-30"
          css={css`
            font-size: 16px;
            line-height: 39px;
          `}
        >
          NAVIGATION
        </h3>

        <CustomLink
          className="block text-white-70 opacity-80"
          css={css`
            font-size: 17px;
            line-height: 27px;
          `}
          to="/"
        >
          Home
        </CustomLink>

        <CustomLink
          className="block text-white-70 opacity-80"
          css={css`
            font-size: 17px;
            line-height: 27px;
          `}
          to="https://www.getswapapp.com/"
        >
          About Us
        </CustomLink>

        <CustomLink
          className="block text-white-70 opacity-80"
          css={css`
            font-size: 17px;
            line-height: 27px;
          `}
          to="/"
        >
          Profile
        </CustomLink>

        <CustomLink
          className="block text-white-70 opacity-80"
          css={css`
            font-size: 17px;
            line-height: 27px;
          `}
          to="https://www.getswapapp.com/terms-of-use"
        >
          Terms of Service
        </CustomLink>

        <CustomLink
          className="block text-white-70 opacity-80"
          css={css`
            font-size: 17px;
            line-height: 27px;
          `}
          to="https://www.getswapapp.com/privacy-policy"
        >
          Private Policy
        </CustomLink>

        <CustomLink
          className="block text-white-70 opacity-80"
          css={css`
            font-size: 17px;
            line-height: 27px;
          `}
          onClick={() =>
            dispatch(sessionSignOut({ locationPathname: location.pathname }))
          }
        >
          Sign Out
        </CustomLink>
      </div>
      <div>
        <h3
          className="text-white-90 opacity-30"
          css={css`
            font-size: 16px;
            line-height: 39px;
          `}
        >
          CONTACT US
        </h3>
        <CustomLink
          className="block text-white-70 opacity-80"
          css={css`
            font-size: 17px;
            line-height: 27px;
          `}
          to="mailto:support@swaphealth.com"
        >
          support@swaphealth.com
        </CustomLink>
      </div>
    </div>
  );
};

export default Footer;
