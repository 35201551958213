import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Select, SelectProps } from "antd";
import React from "react";

import { colors } from "../../assets/css/variable";

export interface AppSelectProps extends SelectProps {
  label?: string;
}
const SelectStyles = styled.div`
  .ant-select {
    background-color: ${colors["snap-alabaster"]};
    .ant-select-selector {
      background-color: ${colors["snap-alabaster"]} !important;
      border-radius: 5px !important;
      height: 100% !important;
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        height: fit-content;
        align-self: center;
        color: ${colors["snap-sonic-silver"]} !important;
      }
    }
  }
`;

const AppSelect: React.FC<AppSelectProps> = ({ label = "", ...rest }) => (
  <SelectStyles>
    {label && (
      <p
        className="text-SNAP_ebony_clay font-600 xs:text-12 xs:mb-2"
        css={css`
          margin-bottom: 10px;
          line-height: 24px;
          font-size: 14px;

          @media (max-width: 550px) {
            font-size: 12px;
            line-height: 21px;
          }
        `}
      >
        {label}
      </p>
    )}
    <Select {...rest} />
  </SelectStyles>
);

export default AppSelect;
