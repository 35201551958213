import { css } from "@emotion/react";
import React from "react";

import CheckImg from "../../assets/images/Check.png";
import GiftCardImg from "../../assets/images/GiftCard.png";
import PayPalImg from "../../assets/images/PayPal.png";
import VenmoImg from "../../assets/images/Venmo.png";
import ZelleImg from "../../assets/images/Zelle.png";

const PayPal = () => (
  <img
    src={PayPalImg}
    alt="PayPal logo"
    css={css`
      max-width: 125px;
    `}
  />
);

const Venmo = () => (
  <img
    src={VenmoImg}
    alt="Venmo logo"
    css={css`
      margin-left: 5px;
      max-width: 102px;
    `}
  />
);

const Check = () => (
  <img
    src={CheckImg}
    alt="check icon"
    css={css`
      margin: 0 25px 0 5px;
      max-width: 50px;
    `}
  />
);

const GiftCard = () => (
  <img
    src={GiftCardImg}
    alt="gift card icon"
    css={css`
      margin: 0 15px 0 -1px;
      max-width: 67px;
    `}
  />
);

const Zelle = () => (
  <img
    src={ZelleImg}
    alt="Zelle logo"
    css={css`
      margin-left: 5px;
      max-width: 83px;
    `}
  />
);

export default {
  PayPal,
  Venmo,
  Check,
  GiftCard,
  Zelle,
};
