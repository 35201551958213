import { css } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "gatsby";
import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import type { SetRequired } from "type-fest";

import { useAddAlert } from "../alert";
import { useUserCashbackEventCreateMutation, useUserGetQuery } from "../api";
import { ButtonBlueFilledRounded, ButtonBlueRounded } from "../components/ui";
import PaymentIcons from "../components/ui/PaymentIcons";
import { handleError } from "../utils/handleError";
import { Congratulations } from "./Congratulations";
import { RewardFormFieldsCheckOrGiftCard } from "./RewardFormFieldsCheckOrGiftCard";
import { RewardFormFieldsPayPal } from "./RewardFormFieldsPayPal";
import { RewardFormFieldsVenmo } from "./RewardFormFieldsVenmo";
import { RewardFormFieldsZelle } from "./RewardFormFieldsZelle";
import type { RewardFormFieldValues } from "./RewardFormFieldValues";
import {
  type RewardFormValidationSchema,
  rewardFormValidationSchemaCheckOrGiftCard,
  rewardFormValidationSchemaPayPalOrVenmo,
  rewardFormValidationSchemaZelle,
} from "./rewardFormValidationSchemas";
import { useSubmitMutation } from "./rewardsApi";
import type { RewardsApiSubmitArgs } from "./RewardsApiSubmitArgs";

type ConfigMap = {
  readonly "Gift Card": {
    readonly FieldsComponent: typeof RewardFormFieldsCheckOrGiftCard;
    readonly IconComponent: () => JSX.Element;
    readonly validationSchema: RewardFormValidationSchema;
  };
  readonly Check: {
    readonly FieldsComponent: typeof RewardFormFieldsCheckOrGiftCard;
    readonly IconComponent: () => JSX.Element;
    readonly validationSchema: RewardFormValidationSchema;
  };
  readonly PayPal: {
    readonly FieldsComponent: typeof RewardFormFieldsPayPal;
    readonly IconComponent: () => JSX.Element;
    readonly validationSchema: RewardFormValidationSchema;
  };
  readonly Venmo: {
    readonly FieldsComponent: typeof RewardFormFieldsVenmo;
    readonly IconComponent: () => JSX.Element;
    readonly validationSchema: RewardFormValidationSchema;
  };
  readonly Zelle: {
    readonly FieldsComponent: typeof RewardFormFieldsZelle;
    readonly IconComponent: () => JSX.Element;
    readonly validationSchema: RewardFormValidationSchema;
  };
};

export type RewardFormProps = {
  // Enforce the presence of a key so that switching between payment methods is
  // guaranteed to reset the form data.
  readonly key: string;

  readonly method: RewardsApiSubmitArgs["method"];
};

const titleCSS = css`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
  color: #222222;
  opacity: 0.8;
`;

const buttonCSS = css`
  width: 250px;
`;

function createIconComponentWithTitle(
  IconComponent: () => JSX.Element,
  title: string,
) {
  const IconComponentWithTitle = () => (
    <div className="flex items-center">
      <IconComponent />
      <span css={titleCSS}>{title}</span>
    </div>
  );
  IconComponentWithTitle.displayName = `IconComponentWithTitle(${title})`;
  return IconComponentWithTitle;
}

const PaymentIconGiftCard = createIconComponentWithTitle(
  PaymentIcons.GiftCard,
  "Gift Card",
);

const PaymentIconCheck = createIconComponentWithTitle(
  PaymentIcons.Check,
  "Check",
);

const configMap: ConfigMap = {
  "Gift Card": {
    FieldsComponent: RewardFormFieldsCheckOrGiftCard,
    IconComponent: PaymentIconGiftCard,
    validationSchema: rewardFormValidationSchemaCheckOrGiftCard,
  },
  Check: {
    FieldsComponent: RewardFormFieldsCheckOrGiftCard,
    IconComponent: PaymentIconCheck,
    validationSchema: rewardFormValidationSchemaCheckOrGiftCard,
  },
  PayPal: {
    FieldsComponent: RewardFormFieldsPayPal,
    IconComponent: PaymentIcons.PayPal,
    validationSchema: rewardFormValidationSchemaPayPalOrVenmo,
  },
  Venmo: {
    FieldsComponent: RewardFormFieldsVenmo,
    IconComponent: PaymentIcons.Venmo,
    validationSchema: rewardFormValidationSchemaPayPalOrVenmo,
  },
  Zelle: {
    FieldsComponent: RewardFormFieldsZelle,
    IconComponent: PaymentIcons.Zelle,
    validationSchema: rewardFormValidationSchemaZelle,
  },
};

function assertFieldsProvided<Values, Keys extends keyof Values>(
  values: Values,
  keys: Keys[],
  // @ts-expect-error Override the TypeScript error about possible unrelated
  // types.
): asserts values is SetRequired<Values, Keys> {
  for (const key of keys) {
    if (typeof key !== "string") continue;
    if (values[key] === undefined) {
      throw new Error(`Expected "${key}" to be provided`);
    }
  }
}

export function RewardForm({ method }: RewardFormProps) {
  const { data: profile } = useUserGetQuery();
  const [complete, setComplete] = useState(false);
  const [triggerUserCashbackEventCreate] = useUserCashbackEventCreateMutation();
  const [triggerSubmit] = useSubmitMutation();
  const addAlert = useAddAlert();

  const { FieldsComponent, IconComponent, validationSchema } =
    configMap[method];

  const {
    control,
    formState: { errors },
    handleSubmit: createSubmitHandler,
  } = useForm<RewardFormFieldValues>({
    defaultValues: {
      address: "",
      city: "",
      email: "",
      firstName: "",
      lastName: "",
      method,
      state: "",
      zipCode: "",
    },
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const handleSubmit = useMemo(() => {
    return createSubmitHandler(async (values) => {
      if (!profile) return;

      try {
        let submitArgs: RewardsApiSubmitArgs;
        assertFieldsProvided(values, ["method"]);

        switch (values.method) {
          case "Check":
          case "Gift Card": {
            assertFieldsProvided(values, [
              "address",
              "city",
              "firstName",
              "lastName",
              "state",
              "zipCode",
            ]);
            submitArgs = {
              amount: profile.current_cashback,
              details: {
                address: values.address,
                city: values.city,
                firstName: values.firstName,
                id: profile.user_id,
                lastName: values.lastName,
                state: values.state,
                zipCode: values.zipCode,
              },
              method: values.method,
              type: "cashout",
              user: profile.email,
            };
            break;
          }

          case "PayPal":
          case "Venmo":
          case "Zelle": {
            assertFieldsProvided(values, ["email"]);
            submitArgs = {
              amount: profile.current_cashback,
              details: {
                email: values.email,
                id: profile.user_id,
              },
              method: values.method,
              type: "cashout",
              user: profile.email,
            };
            break;
          }
        }

        await triggerUserCashbackEventCreate().unwrap();
        await triggerSubmit(submitArgs).unwrap();
        setComplete(true);
      } catch (error) {
        console.error("RewardForm:", error);
        handleError(addAlert, error);
      }
    });
  }, [
    createSubmitHandler,
    profile,
    triggerUserCashbackEventCreate,
    triggerSubmit,
    addAlert,
  ]);

  return complete ? (
    <Congratulations Icon={IconComponent} />
  ) : (
    <div>
      <IconComponent />
      <p className="mt-5">
        {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Malesuada
        feugiat convallis cursus arcu lectus fermentum mauris risus. In mattis
        pellentesque amet massa quis. */}
      </p>
      <p className="my-5">
        <b>Amount ${profile?.current_cashback}</b>
      </p>
      <form onSubmit={handleSubmit}>
        <Controller
          control={control}
          name="method"
          render={({ field }) => <input {...field} type="hidden" />}
        />
        <FieldsComponent control={control} errors={errors} />
        <div className="flex w-full items-center justify-between mt-7">
          <Link to="/rewards">
            <ButtonBlueRounded css={buttonCSS}>BACK</ButtonBlueRounded>
          </Link>
          <ButtonBlueFilledRounded css={buttonCSS} type="submit">
            CONTINUE
          </ButtonBlueFilledRounded>
        </div>
      </form>
    </div>
  );
}
