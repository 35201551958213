import { css } from "@emotion/react";

import { Button, ButtonProps } from "./Button";

export type ButtonBlueFilledRoundedProps = ButtonProps;

export function ButtonBlueFilledRounded({
  className,
  ...rest
}: ButtonBlueFilledRoundedProps): JSX.Element {
  return (
    <Button
      {...rest}
      className={`hover:shadow-md border-2 border-link ${className}`}
      css={css`
        background: #2f80ff !important;
        color: white;
        border-radius: 9999px;
        padding: 0.5rem 1.5rem;
        min-height: 2.4rem;
        font-weight: 400;
      `}
    />
  );
}
