import { css } from "@emotion/react";
import type { RouteComponentProps } from "@reach/router";
import React, { type ReactNode } from "react";

import { useUserGetQuery } from "../api";
import Logo from "../assets/images/SIconWhite72.png";
import Footer from "../components/Layout/MainLayout/Footer";
import { NavBar, SideBar } from "../components/NavBar";

type RewardsSectionProps = Readonly<
  RouteComponentProps & {
    children?: ReactNode;
    className?: string;
  }
>;

export function RewardsSection({ children, className }: RewardsSectionProps) {
  const { data: profile } = useUserGetQuery();

  const navbarItems = [
    {
      heading: "Home",
      route: "/",
      default: true,
      next: "",
    },
    {
      heading: "Download",
      route: "https://www.getswapapp.com/",
      next: "",
    },
    {
      heading: "About Us",
      route: "https://www.getswapapp.com/",
      next: "",
    },
    {
      heading: `${profile?.first_name} ${profile?.last_name}` || "username",
      section: true,
      route: "/",
      default: true,
      next: "",
    },
  ];

  return (
    <>
      <div className="w-full grid grid-cols-1 gap-y-8 gap-x-6 items-start sm:grid-cols-12 lg:gap-x-8">
        <div
          className="aspect-w-2 aspect-h-3 bg-main font-inter text-xl overflow-hidden sm:col-span-4 lg:col-span-4 h-full"
          css={css`
            padding: 3.625rem 4.5rem;
          `}
        >
          <img
            src={Logo}
            alt=""
            className="mb-32"
            css={css`
              width: 72px;
              height: 72px;
            `}
          />

          <SideBar page="rewards" />
        </div>
        <div className="sm:col-span-8 lg:col-span-8 py-16 pr-16 pl-12">
          <NavBar navbarItems={navbarItems} className="menu" />
          <div
            className=""
            css={css`
              min-height: calc(100vh - 20rem);
            `}
          >
            {children}
          </div>
        </div>
      </div>

      <div className={`${className}`}>
        <Footer className="flex-none" />
      </div>
    </>
  );
}
