import { css } from "@emotion/react";
import type { RouteComponentProps } from "@reach/router";
import React from "react";
import type { LiteralUnion } from "type-fest";

import { RewardForm } from "./RewardForm";

type MethodName = keyof typeof methodNameMethodPropMap;

export type RewardsMethodSectionProps = Readonly<
  RouteComponentProps & {
    /**
     * The reward redemption method.
     *
     * This prop is marked as optional and doesn't strictly enforce the list of
     * accepted values because it is intended to have its value injected by a
     * router path.
     */
    methodName?: LiteralUnion<MethodName, string>;
  }
>;

const methodNameMethodPropMap = {
  check: "Check" as const,
  "gift-card": "Gift Card" as const,
  paypal: "PayPal" as const,
  venmo: "Venmo" as const,
  zelle: "Zelle" as const,
};

function isMethodName(
  methodName: string | undefined,
): methodName is MethodName {
  return !!methodName && methodName in methodNameMethodPropMap;
}

export function RewardsMethodSection({
  methodName,
}: RewardsMethodSectionProps) {
  const method = isMethodName(methodName)
    ? methodNameMethodPropMap[methodName]
    : null;

  return (
    <div
      className="mt-6 p-5"
      css={css`
        border: 1.27266px solid #2f80ff;
        box-sizing: border-box;
        border-radius: 13.575px;
      `}
    >
      {method && <RewardForm key={method} method={method} />}
    </div>
  );
}
