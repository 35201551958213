import { css } from "@emotion/react";
import React from "react";
import { Controller } from "react-hook-form";

import ErrorMessage from "../components/BaseComponents/ErrorMessage";
import AppInput from "../components/BaseComponents/Input";
import AppSelect from "../components/BaseComponents/Select";
import USState from "../utils/usState";
import type { RewardFormFieldsProps } from "./RewardFormFieldsProps";

export function RewardFormFieldsCheckOrGiftCard({
  control,
  errors,
}: RewardFormFieldsProps) {
  return (
    <>
      <Controller
        name="firstName"
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <div
            css={css`
              height: 97px;
            `}
          >
            <AppInput
              label="First Name *"
              className="mb-2"
              name={name}
              value={value}
              onChange={onChange}
              placeholder="Brian"
            />
            <ErrorMessage
              className="text-red"
              css={css`
                margin-bottom: 40px;

                @media (min-width: 550px) {
                  margin-bottom: 34px;
                }
              `}
            >
              {errors.firstName?.message}
            </ErrorMessage>
          </div>
        )}
      />
      <Controller
        name="lastName"
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <div
            css={css`
              height: 97px;
            `}
          >
            <AppInput
              label="Last Name *"
              className="mb-2"
              name={name}
              value={value}
              onChange={onChange}
              placeholder="Smith"
            />
            <ErrorMessage
              className="text-red"
              css={css`
                margin-bottom: 40px;

                @media (min-width: 550px) {
                  margin-bottom: 34px;
                }
              `}
            >
              {errors.lastName?.message}
            </ErrorMessage>
          </div>
        )}
      />
      <Controller
        name="address"
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <div
            css={css`
              height: 97px;
            `}
          >
            <AppInput
              label="Address *"
              className="mb-2"
              name={name}
              value={value}
              onChange={onChange}
              placeholder="14 Pine Avenue"
            />
            <ErrorMessage
              className="text-red"
              css={css`
                margin-bottom: 40px;

                @media (min-width: 550px) {
                  margin-bottom: 34px;
                }
              `}
            >
              {errors.address?.message}
            </ErrorMessage>
          </div>
        )}
      />
      <Controller
        name="city"
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <div
            css={css`
              height: 97px;
            `}
          >
            <AppInput
              label="City *"
              className="mb-2"
              name={name}
              value={value}
              onChange={onChange}
              placeholder="Los Angeles"
            />
            <ErrorMessage
              className="text-red"
              css={css`
                margin-bottom: 40px;

                @media (min-width: 550px) {
                  margin-bottom: 34px;
                }
              `}
            >
              {errors.city?.message}
            </ErrorMessage>
          </div>
        )}
      />
      <Controller
        name="zipCode"
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <div
            css={css`
              height: 97px;
            `}
          >
            <AppInput
              label="Zip Code *"
              className="mb-2"
              name={name}
              value={value}
              onChange={onChange}
              placeholder="93004"
            />
            <ErrorMessage
              className="text-red"
              css={css`
                margin-bottom: 40px;

                @media (min-width: 550px) {
                  margin-bottom: 34px;
                }
              `}
            >
              {errors.zipCode?.message}
            </ErrorMessage>
          </div>
        )}
      />
      <Controller
        name="state"
        control={control}
        render={({ field: { onChange, value } }) => (
          <div
            css={css`
              height: 97px;
            `}
          >
            <AppSelect
              options={USState}
              value={value}
              onChange={onChange}
              className="w-330 xs:max-w-190"
              css={css`
                height: 52px;
                margin-right: 15px;

                @media (min-width: 550px) {
                  margin-right: 0;
                }
              `}
              label="State *"
              placeholder="Please Select"
            />
            <ErrorMessage
              className="text-red"
              css={css`
                margin-bottom: 40px;

                @media (min-width: 550px) {
                  margin-bottom: 34px;
                }
              `}
            >
              {errors.state?.message}
            </ErrorMessage>
          </div>
        )}
      />
    </>
  );
}
