import * as yup from "yup";

import type { RewardFormFieldValues } from "./RewardFormFieldValues";
import type { RewardsApiSubmitArgs } from "./RewardsApiSubmitArgs";

export type RewardFormValidationSchema = yup.SchemaOf<RewardFormFieldValues>;

const methodMap: Record<RewardsApiSubmitArgs["method"], true> = {
  "Gift Card": true,
  Check: true,
  PayPal: true,
  Venmo: true,
  Zelle: true,
};

const methodSchema = yup.mixed().oneOf(Object.keys(methodMap));

export const rewardFormValidationSchemaCheckOrGiftCard: RewardFormValidationSchema =
  yup.object().shape({
    address: yup.string().required("Street address is required"),
    city: yup.string().required("City is required"),
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    state: yup.string().required("State is required"),
    zipCode: yup.string().required("Zip code is required"),
    email: yup.string().default(""),
    method: methodSchema,
  });

export const rewardFormValidationSchemaPayPalOrVenmo: RewardFormValidationSchema =
  yup.object().shape({
    address: yup.string().default(""),
    city: yup.string().default(""),
    firstName: yup.string().default(""),
    lastName: yup.string().default(""),
    state: yup.string().default(""),
    zipCode: yup.string().default(""),
    email: yup
      .string()
      .email("Invalid email format")
      .required("Valid email required"),
    method: methodSchema,
  });

export const rewardFormValidationSchemaZelle: RewardFormValidationSchema = yup
  .object()
  .shape({
    address: yup.string().default(""),
    city: yup.string().default(""),
    firstName: yup.string().default(""),
    lastName: yup.string().default(""),
    state: yup.string().default(""),
    zipCode: yup.string().default(""),
    email: yup.string().required("Zelle id required"),
    method: methodSchema,
  });
