import { css } from "@emotion/react";
import React from "react";
import { Controller } from "react-hook-form";

import ErrorMessage from "../components/BaseComponents/ErrorMessage";
import AppInput from "../components/BaseComponents/Input";
import type { RewardFormFieldsProps } from "./RewardFormFieldsProps";

export function RewardFormFieldsVenmo({
  control,
  errors,
}: RewardFormFieldsProps) {
  return (
    <Controller
      name="email"
      control={control}
      render={({ field: { onChange, value, name } }) => (
        <div>
          <AppInput
            label="Enter your Venmo ID"
            className=""
            name={name}
            value={value}
            onChange={onChange}
            placeholder="example@gmail.com"
          />
          <ErrorMessage
            className="text-red"
            css={css`
              margin-bottom: 10px;

              @media (min-width: 550px) {
                margin-bottom: 8px;
              }
            `}
          >
            {errors.email?.message}
          </ErrorMessage>
        </div>
      )}
    />
  );
}
