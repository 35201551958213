import { css } from "@emotion/react";
import type { RouteComponentProps } from "@reach/router";
import { Link } from "gatsby";
import React from "react";

import { ButtonBlueFilledRounded } from "../components/ui/Button";
import PaymentIcons from "../components/ui/PaymentIcons";

export type RewardsRootSectionProps = Readonly<RouteComponentProps>;

const Continue = ({ method }: { method: string }) => (
  <Link to={`/rewards/${method}`}>
    <ButtonBlueFilledRounded className="font-bold">
      Continue
    </ButtonBlueFilledRounded>
  </Link>
);

const descriptorCss = css`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 23.7563px;
  line-height: 29px;
  color: #222222;
  opacity: 0.8;
`;

const paymentMethods = [
  {
    image: <PaymentIcons.PayPal />,
    link: <Continue method="paypal" />,
  },
  {
    image: <PaymentIcons.Venmo />,
    link: <Continue method="venmo" />,
  },
  {
    image: (
      <div className="flex items-center">
        <PaymentIcons.Check />
        <span css={descriptorCss}>Check</span>
      </div>
    ),
    link: <Continue method="check" />,
  },
  {
    image: (
      <div className="flex items-center">
        <PaymentIcons.GiftCard />
        <span css={descriptorCss}>Gift Card</span>
      </div>
    ),
    link: <Continue method="gift-card" />,
  },
  {
    image: <PaymentIcons.Zelle />,
    link: <Continue method="zelle" />,
  },
];

export function RewardsRootSection(_props: RewardsRootSectionProps) {
  return (
    <div>
      <div className="mt-5 md:mt-0 md:col-span-2">
        <div className="sm:overflow-hidden">
          <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
            <div>
              <span
                id="your-rewards"
                className="block w-full border-b border-black text-4xl text-title font-extrabold text-gray-700 mb-7 pb-4"
              >
                Collect your reward!
              </span>
              <div className="mt-1">
                <span className="font-medium text-black text-lg">
                  Choose your payment method:
                </span>
                <ul
                  className="mt-6 px-5"
                  css={css`
                    background: #ffffff;
                    border: 1.27266px solid #2f80ff;
                    box-sizing: border-box;
                    border-radius: 13.575px;
                  `}
                >
                  {paymentMethods.map(({ image, link }, index) => (
                    <li
                      key={index}
                      className="flex p-4"
                      css={css`
                        ${index < paymentMethods.length - 1
                          ? "border-bottom: 1px solid rgba(0,0,0,0.2);"
                          : ""}
                      `}
                    >
                      <div className="flex w-full items-center justify-between">
                        {image}
                        {link}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
